<template>
  <div>
    <ai_loading :isLoading="loading" />
  </div>
</template>

<script>
import { webserver } from "../services/webserver.js";
import ai_loading from "../components/ai/ai_loading.vue";
export default {
  name: "WelcomeSplash",
  async created() {
    var webSetting = await this.fetchAsync();
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    webSetting.logo = "/sites/" + root + "/logo.webp";
    webSetting.logo_bar = "/sites/" + root + "/logo_bar.png";
    console.log(webSetting);
    this.$store.dispatch("set_manifest", webSetting);
  },
  components: {
    ai_loading,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    color: {
      type: String,
      default: "#000000",
    },
  },
  data() {
    return {
      logo: "null",
      loading: true,
    };
  },
  mounted() {
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    const favicon = document.getElementById("favicon");
    favicon.href = root + "/favicon.ico";
    const root_manifest = "/manifests/" + website.replaceAll(".", "") + ".json";
    var mnfst = document.querySelectorAll('[rel="manifest"]');
    mnfst[0].href = root_manifest;
    setTimeout(this.closeSplash, 3000);
    this.get_company();
  },
  computed: {
    imageStyle() {
      let width = this.size + "px";
      return {
        width,
      };
    },
  },
  methods: {
    fetchAsync() {
      const website = this.$store.getters.getHost;
      const root = website.replaceAll(".", "");
      let modulePath = "/manifests/" + root + ".json";
      this.logo = "/sites/" + root + "/logo.webp";
      return fetch(modulePath)
        .then((response) => response.text())
        .then((response) => JSON.parse(response));
    },
    setColor() {
      document.documentElement.style.setProperty("--splash-color", this.color);
    },
    closeSplash() {
      this.loading = false;
      this.$emit("close", true);
    },
    async get_company() {
      var qry = {
        table: "stores",
        filters: [{ field: "url", operator: "=", value: this.$store.getters.getHost }],
      };
      console.log(qry);
      let promise = new Promise((resolve, reject) => {
        webserver(
          "get_table",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });
      let result = await promise;
      // console.log(result);
      if (result.length > 0) {
        this.$store.dispatch("setCompany", result[0]);

        const encryptedText = this.CryptoJS.AES.encrypt(
          JSON.stringify(result[0]),
          process.env.VUE_APP_QR4
        ).toString();
        window.localStorage.setItem("company", encryptedText);
      }
      this.get_products_lst();
    },
    async get_products_lst() {
      if (this.$store.getters.company.account) {
        var qry = {
          account: this.$store.getters.company.account,
          table: "raw_material",
          filters: [
            {
              field: "account",
              operator: "=",
              value: this.$store.getters.company.account,
            },
            { field: "active", operator: "=", value: "1" },
            { field: "price_amount", operator: ">", value: 0 },
          ],
        };
        let promise = new Promise((resolve, reject) => {
          webserver(
            "get_table",
            qry,
            function (data) {
              resolve(data);
            },
            function () {
              reject([]);
            }
          );
        });
        let result = await promise;
        result.forEach((rg) => {
          delete rg.codelp;
          delete rg.costlp;
          delete rg.cost_max;
          delete rg.cost_avg;
          delete rg.sup_qty;
          delete rg.utility;
          delete rg.bp_code;
          rg.qty = 1;
          rg.note = "";
          rg.qty = parseFloat(rg.qty);
          rg.pack = parseFloat(rg.pack);
          rg.cost = parseFloat(rg.cost);
          rg.price = parseFloat(rg.price);
          rg.discount = parseFloat(rg.discount);
        });
        console.log(result);
        this.$store.dispatch("set_products_lst", result);
        setTimeout(this.closeSplash, 3000);
      }
    },
  },
};
</script>
