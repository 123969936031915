<template>
  <div v-if="isLoading" class="loading-container">
    <v-progress-circular
      v-for="n in 10"
      :key="n"
      :size="randomSize(120, 300) + 5"
      :width="randomSize(1, 3)"
      :indeterminate="true"
      :color="randomColor(0, 5)"
      :rotate="randomSize(0, 360)"
      class="outer-circle"
    >
      <v-avatar color="black" size="130">
        <v-img contain height="120" :src="logo"></v-img>
      </v-avatar>
    </v-progress-circular>

    <div class="core"></div>
    <div class="loading-text">AI is processing...</div>
  </div>
</template>

<script>
export default {
  name: "FuturisticLoader",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.logo = "../sites/" + root + "/logo.webp";
    console.log("Logo: " + this.logo);
  },
  methods: {
    randomSize(a, b) {
      return Math.floor(Math.random() * (a - b + 1)) + b;
    },
    randomColor(a, b) {
      var clr = Math.floor(Math.random() * (a - b + 1)) + b;
      return this.colors[clr];
    },
  },
  data() {
    return {
      colors: [
        "blue lighten-1",
        "blue lighten-2",
        "blue lighten-3",
        "blue lighten-4",
        "blue lighten-5",
        "blue darken-1",
      ],
    };
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 9999;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.7) 30%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(0, 0, 0, 0) 100%
  );
}

.outer-circle {
  position: absolute;
  animation: pulse 2s infinite ease-in-out;
}
</style>
