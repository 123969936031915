<template>
  <div class="pt-0" background-color="black">
    <ai_loading :isLoading="isLoading" />
    <v-app-bar dark color="#000000" app>
      <v-app-bar-nav-icon class="logo_bar">
        <v-img contain height="40" :src="this.manifest.logo_bar"></v-img>
      </v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-tabs v-if="!$vuetify.breakpoint.xsOnly">
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-spacer></v-spacer>
        <v-tab v-for="item in menu" :key="item.name">
          {{ item.name }}
        </v-tab>
        <Loginform />
      </v-tabs>
      <v-menu
        transition="slide-x-transition"
        bottom
        right
        offset-y
        v-if="$vuetify.breakpoint.xsOnly"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <img
              :src="$store.getters.profile.Picture"
              v-if="$store.getters.profile.Picture"
            />
            <v-icon v-else>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <template>
          <v-list v-if="this.$store.state.profile.code">
            <v-list-item to="Profile">
              <v-list-item-avatar>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ this.$store.state.profile.name }}
                </v-list-item-title>
                <small>{{ this.$store.state.profile.email }}</small>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item to="MyOrders">
              <v-list-item-avatar>
                <v-icon>mdi-file-document-multiple-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Mis Ordenes </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item @click="logout">
              <v-list-item-avatar>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Salir</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item to="Registration">
              <v-list-item-avatar>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Registrarse </v-list-item-title>
                <small>{{ this.$store.state.profile.email }}</small>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </v-app-bar>

    <v-carousel
      color="transparent"
      cycle
      height="90vh"
      :show-arrows="true"
      :hide-delimiters="true"
      interval="10000"
    >
      <v-carousel-item v-for="(item, i) in items" :key="i">
        <v-sheet color="black" height="100%" tile>
          <v-layout fill-height align-center justify-center>
            <v-flex xs10 sm10 md8 align="center" justify="center">
              <v-row class="fill-height" align="center" justify="end">
                <v-col md="12">
                  <div class="text-h3 white--text">
                    <h1
                      class="text-md-h2 text-h4 font-weight-bold textShadow"
                      v-html="item.title"
                    ></h1>
                    <p class="text-md-h4 text-h6 textShadow" v-html="item.text"></p>
                  </div>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-bottom-navigation app dark grow>
      <v-spacer></v-spacer>
      <ai_agent />
      <SignIn />
      <Loginform />
      <v-spacer></v-spacer>
    </v-bottom-navigation>
  </div>
</template>

<script>
import Loginform from "../components/ai/Login.vue";
import ai_agent from "../components/ai/ai_agent.vue";
import SignIn from "../components/ai/SignIn.vue";
//import ai_speech from "../components/ai/ai_text_speech.vue";
//import ai_test from "../components/ai/ai_GPT_test.vue";
//import ai_rut from "../components/ai/ai_rut.vue";
//import ai_cars from "../components/ai/ai_cars.vue";
import ai_loading from "../components/ai/ai_loading.vue";
//import ai_X from "../components/ai/ai_xposts.vue";

export default {
  name: "Welcome",
  components: { Loginform, ai_agent, ai_loading, SignIn },
  data() {
    return {
      isLoading: false,
      login_dialog: false,
      carouselHeight: "100vh",
      weblogo: null,
      manifest: {},
      fab: null,
      color: "",
      flat: null,
      isXs: false,
      menu: [
        {
          id: 0,
          name: "Inicio",
          icon: "home",
          to: "/",
          color: "warning",
        },
        {
          id: 2,
          name: "Clientes",
          icon: "employees",
          color: "warning",
          to: "Inventario",
        },
        {
          id: 30,
          name: "Proveedores",
          icon: "supplier",
          color: "warning",
          to: "Inventario",
        },
        {
          id: 40,
          name: "Reparto",
          icon: "truck",
          color: "warning",
          to: "Inventario",
        },
      ],
      items: [
        {
          title: "<h1>AI-POS</h1>",
          text:
            "<br>Estoy aquí para ayudarte a mejorar <br>la eficiencia de tu negocio con AI-POS. <br>¡Pruébalo ahora!",
        },
        {
          title: "Interfaz Intuitiva y Fácil de Usar",
          text:
            "AI-POS cuenta con una interfaz amigable que permite a los clientes interactuar con su dispositivo de forma natural. ",
        },
        {
          title: "Reconocimiento de Voz Preciso",
          text:
            "Tecnología avanzada de reconocimiento de voz entiende y procesa órdenes en lenguaje natural.",
        },
        {
          title: "Análisis Avanzado de Datos con AI",
          text:
            "Realiza un análisis avanzado de datos utilizando inteligencia artificial.",
        },
        {
          src: require("../assets/image3.webp"),
          title: "Plataforma Exclusiva para Concesionarios y Agentes",

          text: "",
        },
      ],
      dialog: false,
      icons: {
        project: "mdi-text-box-search-outline",
        weather: "mdi-weather-partly-snowy-rainy",
        crops: "mdi-sprout-outline",
        poly: "mdi-vector-polygon",
        dB: "mdi-database-cog-outline",
        chart: "mdi-chart-bell-curve-cumulative",
        clt: "mdi-account-circle-outline",
        set: "mdi-cog-transfer-outline",
        info: "mdi-book-information-variant",
        cale: "mdi-calendar-month-outline",
        invoice: "mdi-receipt",
        pedido: "mdi-file-document-edit-outline",
        truck: "mdi-truck-fast-outline",
        warehouse: "mdi-warehouse",
        inventory: "mdi-package-variant-closed",
        supplier: "mdi-truck-check-outline",
        orders: "mdi-file-document-edit-outline",
        store: "mdi-store-outline",
        clock: "mdi-clock-check-outline",
        map: "mdi-map-marker-radius",
        home: "mdi-home-circle-outline",
        forklift: "mdi-forklift",
        cash: "mdi-cash-register",
        employees: "mdi-account-multiple-outline",
        account: "mdi-account-lock-outline",
        accountGroup: "mdi-account-group-outline",
        account_tie: "mdi-account-tie",
        fish: "mdi-fish",
        cube_not: "mdi-package-variant-closed-remove",
        cube_pos: "mdi-package-variant-plus",
        garage: "mdi-garage-variant",
        currency: "mdi-currency-usd",
      },
      open: [],
      plans: [
        {
          title: "AI-POS Aurora",
          prices: {
            monthly: "$9.99/month",
            yearly: "$99.99/year",
          },
          features: [
            "Gestión básica de inventarios",
            "Reportes de ventas estándar",
            "Soporte básico",
          ],
          buttonText: "Choose Aurora",
        },
        {
          title: "AI-POS Spectrum",
          prices: {
            monthly: "$19.99/month",
            yearly: "$199.99/year",
          },
          features: [
            "Todas las funciones de Aurora",
            "Análisis avanzado de ventas",
            "Integración con múltiples dispositivos",
            "Soporte prioritario",
          ],
          buttonText: "Choose Spectrum",
        },
        {
          title: "AI-POS Apex",
          prices: {
            monthly: "$29.99/month",
            yearly: "$299.99/year",
          },
          features: [
            "Todas las funciones de Spectrum",
            "Predicciones de ventas con AI",
            "Reportes personalizados y detallados",
            "Soporte premium 24/7",
          ],
          buttonText: "Choose Apex",
        },
      ],
    };
  },
  beforeMount() {
    // const website = this.$store.getters.getHost;
    // const root = website.replaceAll(".", "");
    // this.weblogo = "../../" + root + "/logo.webp";
  },
  mounted() {
    this.$nextTick(() => {
      this.updateHeight();
      window.addEventListener("resize", this.updateHeight);
    });
    this.$store.dispatch("setNavStatus", false);
    this.$store.dispatch("set_menu", false);
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.$vuetify.goTo(0);
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
    "$store.getters.getManifest"(newVal) {
      if (newVal) {
        this.manifest = newVal;
      }
    },
  },
  methods: {
    updateHeight() {
      this.carouselHeight = window.innerHeight + "px";
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    updateForm(item) {
      //if (item.length > 0) console.log(item[0].name, item[0].to);
      if (item[0].to) this.$router.push(item[0].to);
    },
    updateOpen(openItems) {
      this.open = openItems.length ? [openItems[openItems.length - 1]] : [];
    },
    logout() {
      this.$store.state.navStatus = false;
      this.$router.push("/login/");
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateHeight);
  },
};
</script>

<style scoped>
.plan-title {
  font-family: "Orbitron", sans-serif !important;
  font-weight: 700 !important;
}

.plan-price {
  font-family: "Orbitron", sans-serif;
  font-weight: 500;
}
.card_title {
  font-size: 1.5rem;
  font-weight: bold;
  height: 100px !important;
  text-wrap: pretty !important;
}
.textShadow {
  font-family: "Orbitron", sans-serif !important;
  text-shadow: rgb(255, 255, 255) 25px 0 25px !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: white !important;
}
.plan-card {
  display: flex;
  flex-direction: column;
}
.v-card {
  display: flex;
  flex-direction: column;
}
.v-card-text {
  flex-grow: 1;
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
.v-window__left,
.v-window__right {
  display: none !important;
}
</style>
