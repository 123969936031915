<template>
  <v-dialog
    v-model="dialog"
    scrollable
    fullscreen
    :width="bestWidth > 600 ? 450 : bestWidth"
    :height="this.best_height"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn
        fixed
        fab
        dark
        color="blue"
        v-bind="attrs"
        v-on="on"
        absolute
        top
        right
        class="floating-btn"
        v-if="!$vuetify.breakpoint.xsOnly"
      >
        <v-icon>mdi-chat</v-icon>
      </v-btn> -->
      <v-btn dark v-bind="attrs" v-on="on">
        <v-icon>mdi-chat</v-icon>
      </v-btn>
    </template>
    <v-card dark>
      <v-card-title class="pa-0">
        <v-toolbar dark xlarge>
          <v-toolbar-title>{{ $store.getters.getManifest.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card flat v-if="!this.user_ok">
        <v-card-title>Iniciar charla</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Nombre"
              required
            ></v-text-field>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Correo electrónico"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="submit">Ingreso</v-btn>
        </v-card-actions>
      </v-card>
      <v-card-text ref="contentContainer" class="overflow-y-auto pa-0 pt-6 chat">
        <v-list color="transparent">
          <v-list-item
            v-for="(message, index) in chatHistory"
            :key="index"
            :class="message.role === 'user' ? 'bubble-mine' : 'bubble-theirs'"
            three-line
          >
            <v-list-item-avatar>
              <v-avatar size="50">
                <v-icon large v-if="message.role === 'user'"
                  >mdi-account-circle-outline</v-icon
                >
                <v-icon large color="yellow" v-else> mdi-face-agent</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content
              :class="{
                'text-xs': $vuetify.breakpoint.xs,
                'text-md': $vuetify.breakpoint.md,
              }"
              v-html="formattedText(message.content)"
            >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="pa-2">
        <v-text-field
          v-model="userInput"
          placeholder="Escribe un mensaje"
          outlined
          hide-details
          class="pa-0 ma-0"
          @keyup.enter="add_message"
          x-large
        >
          <template v-slot:append>
            <v-btn
              icon
              :class="{ 'active-tts': textToSpeechActive }"
              @click="toggleTextToSpeech"
            >
              <v-icon :color="textToSpeechActive ? 'blue' : ''">
                {{ textToSpeechActive ? "mdi-volume-off" : "mdi-volume-high" }}
              </v-icon>
            </v-btn>
            <v-btn icon @click="add_message" :loading="loading_chat">
              <v-icon v-if="!loading_chat">mdi-send</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <v-btn
          fab
          large
          :class="{ 'active-microphone': voiceCommandsActive }"
          @click="startRecognition"
        >
          <v-icon :color="voiceCommandsActive ? 'red' : ''">
            {{ voiceCommandsActive ? "mdi-microphone-off" : "mdi-microphone" }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { NGMGpt } from "../../services/webserver.js";
export default {
  name: "AiAgent",
  props: {},
  data() {
    return {
      user_ok: localStorage.getItem("user_ok"),
      best_height: window.innerHeight,
      bestWidth: window.innerWidth,
      dialog: false,
      userInput: "",
      chatHistory: [],
      loading_chat: false,
      thread_id: null,
      chatLoginDialog: false,
      Init_dialog: false,
      valid: true,
      name: "",
      nameRules: [(v) => !!v || "El nombre es requerido"],
      email: "",
      emailRules: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "Debe ser un correo electrónico válido",
      ],
      voiceCommandsActive: false,
      textToSpeechActive: false,
      recognition: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
    this.name = localStorage.getItem("name");
    this.email = localStorage.getItem("email");
    this.user_ok = localStorage.getItem("user_ok");
    this.onResize();

    if (this.user_ok) {
      if (!this.thread_id) {
        var msg = "Mi nombre es " + this.name + " y mi correo es " + this.email;
        this.chatInit(msg);
      }
    }
  },
  methods: {
    toggleTextToSpeech() {
      this.textToSpeechActive = !this.textToSpeechActive;
      this.$emit("toggle-text-to-speech", this.textToSpeechActive);
    },
    chatInit(msg) {
      var message = {
        role: "user",
        content: [{ type: "text", text: msg }],
        // attachments: [
        //   {
        //     file_id: file,
        //     tools: [
        //       {
        //         type: "file_search",
        //       },
        //     ],
        //   },
        // ],
      };
      this.put_message(message);
    },
    submit() {
      if (this.$refs.form.validate()) {
        localStorage.setItem("name", this.name);
        localStorage.setItem("email", this.email);
        localStorage.setItem("user_ok", true);
        this.name = localStorage.getItem("name");
        this.email = localStorage.getItem("email");
        this.user_ok = localStorage.getItem("user_ok");
      }
      //var file = "file-4d91wMDYfCVj098pSLnY8LmI";
      var msg = "Mi nombre es " + this.name + " y mi correo es " + this.email;
      this.chatInit(msg);
    },
    onResize() {
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_width = window.innerWidth;
      } else {
        this.best_width = window.innerWidth / 2;
      }
    },
    add_message() {
      if (!this.userInput.trim()) return;

      var message = {
        role: "user",
        content: [{ type: "text", text: this.userInput }],
      };
      this.chatHistory.push({ role: "user", content: this.userInput });
      this.put_message(message);
      this.userInput = "";
      this.$nextTick(() => {
        const container = this.$refs.contentContainer;
        if (container.scrollHeight) {
          container.scrollTop = container.scrollHeight;
        }

        this.closeKeyboard();
      });
    },
    async put_message(message) {
      var qry = {
        thread_id: this.thread_id,
        message: message,
      };

      this.loading_chat = true;
      let promise = new Promise((resolve, reject) => {
        NGMGpt(
          "add_message",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });

      let response = await promise;
      console.log(response);
      this.thread_id = response.thread_id;
      this.loading_chat = false;
      var first_id = response.messages.first_id;
      var dat = response.messages.data;
      var msg = dat.find((o) => o.id === first_id);

      const botMessageContent = msg.content[0].text.value;
      if (this.textToSpeechActive) this.speak(this.cleanTextForTTS(botMessageContent));
      const botMessage = { role: "bot", content: botMessageContent };

      this.chatHistory.push(botMessage);

      this.$nextTick(() => {
        const container = this.$refs.contentContainer;
        if (container.scrollHeight) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    textToSpeechFiltered(text) {
      text = text.replace(/\*\*(.*?)\*\*/g, "$1");
      text = text.replace(/\*(.*?)\*/g, "$1");
      text = text.replace(/`(.*?)`/g, "$1");
      // Eliminar paréntesis
      text = text.replace(/\(.*?\)/g, "");
      // Eliminar encabezados, guiones y otros símbolos de Markdown
      text = text.replace(/^#.*$/gm, "");
      text = text.replace(/[-*] /g, "");
      // Eliminar líneas en blanco adicionales
      text = text.replace(/\n+/g, "\n").trim();
      return text;
    },
    cleanTextForTTS(text) {
      // Eliminar listas
      let cleanedText = text.replace(/(?:^\s*[-*•]\s+|\d+\.\s+)/gm, "");

      // Eliminar elementos de Markdown
      cleanedText = cleanedText
        .replace(/!\[.*?\]\(.*?\)/g, "") // Imágenes
        .replace(/\[.*?\]\(.*?\)/g, "") // Enlaces
        .replace(/`{1,3}.*?`{1,3}/g, "") // Código en línea
        .replace(/#{1,6}\s+/g, "") // Encabezados
        .replace(/\*\*.*?\*\*/g, "") // Negritas
        .replace(/_.*?_/g, "") // Cursivas
        .replace(/>\s+/g, "") // Citas
        .replace(/-{3,}/g, ""); // Líneas horizontales

      // Dividir en párrafos
      const paragraphs = cleanedText.split(/\n\s*\n/);

      // Limitar a los dos primeros párrafos
      if (paragraphs.length > 2) {
        cleanedText = paragraphs.slice(0, 2).join("\n\n");
      }

      return cleanedText.trim();
    },
    formattedText(texto) {
      // Detectar y aplicar formato HTML a los títulos
      texto = texto.replace(/^(#+)\s*(.*)$/gm, function (_, hashTags, titleText) {
        const nivel = hashTags.length;
        return `<h${nivel}>${titleText}</h${nivel}>`;
      });

      // Detectar y aplicar formato HTML a los elementos destacados
      texto = texto.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

      // Detectar y aplicar formato HTML a las listas
      texto = texto.replace(/^-\s+(.*)$/gm, "<li>$1</li>");
      texto = texto.replace(/<\/li>\n<li>/g, "</li><li>");
      texto = texto.replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>");

      // Detectar y aplicar formato HTML a las listas
      texto = texto.replace(/^-\s+(.*)$/gm, "<li>$1</li>");
      texto = texto.replace(/^\s+-\s+(.*)$/gm, "<ul><li>$1</li></ul>"); // Segundo nivel de indentado
      texto = texto.replace(/<\/li>\n<li>/g, "</li><li>");
      texto = texto.replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>");

      // Detectar y aplicar formato HTML a los párrafos
      texto = texto.replace(/^(?!<[hultd]).+$/gm, "<p>$&</p>");

      // Detectar y aplicar formato HTML a las URLs
      texto = texto.replace(
        /(https?:\/\/[^\s)]+)/g,
        '<a target="_blank" href="$1"> Click Aqui </a>'
      );
      return texto;
    },
    generatePrompt() {
      return this.chatHistory
        .map((message) => `${message.role}: ${message.content}`)
        .join("\n");
    },
    scrollToEnd() {
      const container = this.$refs.messagesContainer;
      container.scrollTop = container.scrollHeight;
    },
    closeKeyboard() {
      const input = this.$refs.userInput;
      if (input) {
        input.blur();
      }
    },
    speak(dta) {
      window.speechSynthesis.cancel();
      const fragments = dta.split(/(?<=[.,])/); // divide el texto en fragmentos que terminan en comas o puntos

      let delay = 0; // Inicializar el retraso
      const commaPause = 10; // 300ms para comas
      const periodPause = 400; // 500ms para puntos

      fragments.forEach((fragment) => {
        const speech = new SpeechSynthesisUtterance();
        speech.rate = 1.2;
        speech.pitch = 1;
        speech.text = fragment.trim();
        speech.lang = "es-CO"; // Puedes cambiar esto a cualquier idioma que quieras

        // Agregar el speech synthesis al timeout
        setTimeout(() => {
          window.speechSynthesis.speak(speech);
        }, delay);

        // Ajustar el retraso basado en el último carácter del fragmento
        if (fragment.endsWith(",")) {
          delay += commaPause;
        } else if (fragment.endsWith(".")) {
          delay += periodPause;
        } else {
          delay += 100; // Pequeño retraso por defecto entre fragmentos sin puntuación
        }
      });
    },

    startRecognition() {
      if (this.voiceCommandsActive) {
        this.voiceCommandsActive = false;
        this.$emit("toggle-voice-commands", this.voiceCommandsActive);
        this.recognition.stop();
      } else {
        this.voiceCommandsActive = true;
        this.$emit("toggle-voice-commands", this.voiceCommandsActive);

        this.recognition = new (window.SpeechRecognition ||
          window.webkitSpeechRecognition ||
          window.mozSpeechRecognition ||
          window.msSpeechRecognition)();
        this.recognition.lang = "es-US"; // Puedes cambiar esto a cualquier idioma que quieras
        this.recognition.continuous = false;
        this.recognition.interimResults = false;
        this.recognition.maxAlternatives = 100;

        this.recognition.start();

        this.recognition.onresult = (event) => {
          this.voiceCommandsActive = false;
          this.$emit("toggle-voice-commands", this.voiceCommandsActive);
          this.userInput = event.results[0][0].transcript;
          this.add_message();
        };

        this.recognition.onspeechend = () => {
          //this.voiceCommandsActive = !this.voiceCommandsActive;
          //this.$emit("toggle-voice-commands", this.voiceCommandsActive);
          this.recognition.stop();
        };

        this.recognition.onerror = (event) => {
          this.voiceCommandsActive = !this.voiceCommandsActive;
          this.$emit("toggle-voice-commands", !this.voiceCommandsActive);

          console.log("Error occurred in recognition: " + event.error);
        };
      }
    },
  },
  watch: {
    textToSpeechActive(newVal) {
      if (newVal) {
        this.speak("Texto a voz activado");
      } else {
        this.speak("Texto a voz desactivado");
        window.speechSynthesis.cancel();
      }
    },
    dialog(newVal) {
      if (newVal) {
        console.log("El componente está abierto");

        this.Init_dialog = true;
      } else {
        console.log("El componente está cerrado");
      }
    },
  },
};
</script>

<style scoped>
.bubble-mine {
  justify-content: flex-end;
}

.bubble-theirs {
  justify-content: flex-start;
}

.avatar-right {
  order: 2;
}

.avatar-left {
  order: 1;
}
.floating-btn {
  top: 80px !important;
  z-index: 1000;
}
.pa-0 {
  padding: 0 !important;
}

.ma-0 {
  margin: 0 !important;
}

.active-microphone v-icon {
  color: red !important;
}

.chat {
  font-family: "Orbitron", sans-serif !important;
  text-shadow: rgb(255, 255, 255) 10px 0 25px !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

  color: white !important;
}

.active-tts v-icon {
  color: blue !important;
}

#markdown,
#html {
  width: 45%;
  height: 300px;
  margin: 20px;
  float: left;
}
#html {
  border: 1px solid #ccc;
  padding: 10px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
table,
th,
td {
  border: 1px solid black;
}
th,
td {
  padding: 10px;
  text-align: left;
}

.text-xs {
  font-size: 1.5em !important;
}
.text-md {
  font-size: 2em !important;
}
</style>
