import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../views/Landing.vue'
import store from "../store"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '/',
        component: Landing,
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Login',
        name: 'Login',
        component: () =>
            import('../views/Login.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },

    {
        path: '/Welcome',
        name: 'Welcome',
        component: () =>
            import('../views/admin/Welcome.vue'),
        meta: {
            requiresAuth: true,
            is_admin: false
        }
    },
    {
        path: '/Settings',
        name: 'Settings',
        component: () =>
            import('../views/admin/Settings.vue'),
        meta: {
            requiresAuth: true,
            is_admin: false
        }
    },
    {
        path: '/Pos',
        name: 'Pos',
        component: () =>
            import('../views/admin/Pos.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/ClientOrders',
        name: 'ClientOrders',
        component: () =>
            import('../views/admin/Client_orders.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/MyStore',
        name: 'MyStore',
        component: () =>
            import('../views/admin/Store.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },

    {
        path: '/Companies',
        name: 'Companies',
        component: () =>
            import('../views/FE/FE_companies.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/SettingsFE',
        name: 'SettingsFE',
        component: () =>
            import('../views/FE/FE_settings.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Stores',
        name: 'Stores',
        component: () =>
            import('../views/admin/Companies.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },

    {
        path: '/Supplier',
        name: 'Supplier',
        component: () =>
            import('../views/admin/Supplier.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Products',
        name: 'Products',
        props: true,
        component: () =>
            import('../views/admin/Products.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Catalogue',
        name: 'Catalogue',
        props: true,
        component: () =>
            import('../views/admin/Catalogue.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Vehiculos',
        name: 'Vehiculos',
        props: true,
        component: () =>
            import('../views/Autotrader/Vehiculos.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/PriceList',
        name: 'PriceList',
        props: true,
        component: () =>
            import('../views/admin/PriceList.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Clients',
        name: 'Clients',
        props: true,
        component: () =>
            import('../views/admin/Clients.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/CarItem',
        name: 'CarItem',
        props: true,
        component: () =>
            import('../views/Autotrader/vehicle_inventory.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/AT_DB',
        name: 'AT_DB',
        props: true,
        component: () =>
            import('../views/Autotrader/at_db.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    // {
    //     path: '/Inventory2',
    //     name: 'Inventory2',
    //     props: true,
    //     component: () =>
    //         import('../views/Autotrader/Inventory.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         is_admin: true
    //     }
    // },
    {
        path: '/Inventory',
        name: 'Inventory',
        props: true,
        component: () =>
            import('../views/admin/Inventory.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Vehicles',
        name: 'Vehicles',
        props: true,
        component: () =>
            import('../views/Autotrader/Vehicles.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Vehicle',
        name: 'Vehicle',
        props: true,
        component: () =>
            import('../views/Autotrader/Vehicle.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
   
    {
        path: '/ClientsMap',
        name: 'ClientsMap',
        component: () =>
            import('../views/admin/ClientsMap.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/docs',
        name: 'docs',
        props: true,
        component: () =>
            import('../views/admin/Docs.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Doc_r',
        name: 'Doc_r',
        props: true,
        component: () =>
            import('../views/admin/Doc_r.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Doc_e',
        name: 'Doc_e',
        props: true,
        component: () =>
            import('../views/admin/Doc_e.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/DashBoard',
        name: 'DashBoard',
        component: () =>
            import('../views/admin/DashBoard.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },

    {
        path: '/About',
        name: 'About',
        component: () =>
            import('../views/About.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    // {
    //     path: '/Login',
    //     name: 'Login',
    //     props: true,
    //     component: () =>
    //         import('../views/admin/Login.vue'),
    //     meta: {
    //         requiresAuth: false,
    //         is_admin: false
    //     }
    // },
    {
        path: '/invoices_inbox',
        name: 'invoices_inbox',
        props: true,
        component: () =>
            import('../views/admin/Invoices_inbox.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/contents',
        name: 'contents',
        props: true,
        component: () =>
            import('../views/admin/Contents.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Puc',
        name: 'Puc',
        props: true,
        component: () =>
            import('../views/admin/Puc.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    ]

const router = new VueRouter({
     mode: 'history',
    duplicateNavigationPolicy: 'ignore', // other options: 'ignore', 'reload' and 'reject'
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.profile) {
            if (store.state.profile.code) {
                //console.log("===>",to);
                next()
            } else {
                next({
                    name: 'Login',
                    params: { nextUrl: to.fullPath }
                })
            }
            return
        } else {
            
            next({
                name: 'Login',
                params: { nextUrl: to.fullPath }
            })
        }
    } else {
        next()
    }
})

export default router